<template>
  <div>
    <filter-slot
      :filter="filterStatus"
      :filter-principal="filterPrincipal"
      :total-rows="totalRows"
      :paginate="paginate"
      :start-page="startPage"
      :to-page="toPage"
      @reload="reloadTable()"
    >
      <status-change-table
        slot="table"
        :my-provider="myProvider"
        :fields="fields"
        ref="refTable"
        :current-page="paginate.currentPage"
        :sort-by="sortBy"
        :sort-desc="sortDesc"
        :paginate="paginate"
        :module-id="moduleId"
        :role-id="currentUser.role_id"
        @show-tracking-list="showModalTrackingList($event)"
      >
      </status-change-table>
    </filter-slot>

    <ModalTrackingList
      v-if="showTrackingModal"
      @close-tracking-list="closeModalTrackingList($event)"
      :tracking-list="trackingList"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import StatusChangeService from "@/views/crm/views/status-change/service/status-change.service";
import ModalTrackingList from "@/views/crm/views/status-change/components/ModalTrackingList.vue";
import FilterSlot from "@/views/crm/views/status-change/components/slots/FilterSlot.vue";
import filters from "../data/filters.status-change.data";
import fields from "../data/fields.status-change.data";
import StatusChangeTable from "@/views/crm/views/status-change/components/StatusChangeTable.vue";

export default {
  name: "LoyalTab",
  components: {
    ModalTrackingList,
    FilterSlot,
    "status-change-table": StatusChangeTable,
  },
  data() {
    return {
      totalRows: 0,
      paginate: {
        currentPage: 1,
        perPage: 10,
      },
      sortBy: "", // created_at
      sortDesc: true,
      //fields data
      arrayColumns: fields,
      startPage: null,
      endPage: "",
      toPage: null,
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Status Change...",
        model: "",
      },
      filter: filters,
      showTrackingModal: false,
      items: [],
      trackingList: [],
    };
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/currentUser",
      G_STATES: "CrmGlobalStore/G_STATES",
    }),

    moduleId: function () {
      return this.$route.matched[0].meta.module;
    },

    fields: function () {
      if (this.arrayColumns.find((column) => column.key === "actions")) {
        this.arrayColumns.splice(
          this.arrayColumns.findIndex((column) => column.key === "actions"),
          1
        );
      }
      return this.arrayColumns.filter((column) => column.visible);
    },
    filterStatus: function () {
      return this.filter;
    },
  },
  created() {
    this.setOptionsOnFilters();
  },
  methods: {
    setOptionsOnFilters() {
      this.filter[2].options = [
        { value: "", label: "ALL" },
        { value: "LOYAL", label: "CONNECTION" },
        { value: "TRANSITION", label: "SENT" },
        { value: "REJECTED", label: "REJECTED" },
        { value: "RECYCLE", label: "RECYCLE" },
        { value: "RECOVERED", label: "RECOVERED" },
      ];
    },

    reloadTable: function () {
      this.$refs["refTable"].reloadTable();
    },

    closeModalTrackingList(event) {
      this.showTrackingModal = false;
    },
    showModalTrackingList: async function (accountId) {
      const { data } = await StatusChangeService.getTrackingListByClientId({
        id: accountId,
      });
      this.trackingList = data;
      this.showTrackingModal = true;
    },
    myProvider: async function (ctx) {
      try {
        let params = {
          advisor_code: "",
          per_page: ctx.perPage || 10,
          page: ctx.currentPage || 1,
          change_status: 1,
          client_connection_state: this.filter[2].model || "",
          client_mobile: "",
          client_state: "",
          date_from: this.filter[0].model || "",
          date_to: this.filter[1].model || "",
          in_connection: "",
          lead_name: this.filterPrincipal.model || "",
          role_id: "",
        };
        const { data, current_page, per_page, total, from, to } =
          await StatusChangeService.getClientsConnection(params);
        this.items = data;
        this.startPage = from;
        this.paginate.currentPage = current_page;
        this.paginate.perPage = per_page;
        this.totalRows = total;
        this.toPage = to;
        return this.items || [];
      } catch (error) {
        return [];
      }
    },
  },
};
</script>